import axios from 'axios'
import router from '@/router'
import store from '@/store'

const USER_AUTH_KEY = 'USER_AUTH_KEY'

// TODO add default header base on language that is chosen

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.BASE_URL
axios.defaults.validateStatus = (s) => s !== 401

axios.interceptors.response.use(function (data, d2) {
  return Promise.resolve(data)
  // return Promise.resolve(data)
}, function (error, r) {
  if (error) {
    const originalRequest = error.config
    if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('Logout')
      if (router.history.current.path === '/login') {
        console.log(error)
        return Promise.reject(error.response)
      }
      router.push('/login')
      return Promise.reject(error.response)
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (store.getters.isAuthenticated) {
      // check permissions
      if (to.meta.permission) {
        console.log('Router ->', 'going to', to.path, 'required', to.meta.permission)
        if (Array.isArray(to.meta.permission)) {
          for (const p of to.meta.permission) {
            if (methods.hasPermission(store.state, p)) {
              console.log('Router ->', 'going to', to.path, 'you have one of required permission', to.meta.permission)

              return next()
            }
          }
          console.log('Router ->', 'going to', to.path, 'you DOES NOT have one of required permission', to.meta.permission)

          return
        } else if (methods.hasPermission(store.state, to.meta.permission)) {
          console.log('Router ->', 'going to', to.path, 'you have required permission', to.meta.permission)
          next()
        } else {
          console.log('Router ->', 'going to', to.path, 'you DOES NOT have required permission', to.meta.permission)
          // return to previous and show error. You do not have permission to go here
          // next(from)
          return
        }
      } else {
        console.log('Router ->', 'going to', to.path, 'not required additional permission')
        next()
      }
      return
    }
    console.log('Router ->', 'going to', to.path, 'required to be log in')
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

const axiosSetUserAuth = (userAuth) => {
  if (userAuth) {
    if (userAuth.accessToken) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + userAuth.accessToken
    }
    if (userAuth.accessId) {
      axios.defaults.headers.common['X-AccessId'] = userAuth.accessId
    }
    if (userAuth.username) {
      axios.defaults.headers.common['X-Username'] = userAuth.identification
    }
  }
}
// load from local storage the user auth
const localUserAuth = JSON.parse(localStorage.getItem(USER_AUTH_KEY))
axiosSetUserAuth(localUserAuth)

const state = {
  user: localUserAuth || {
    username: null,
    accessId: null,
    accessToken: null,
    permissions: [],
    roles: [] // Admin, Manager, Employee, Support, Account
  }
}

const methods = {
  hasPermission: (state, permission) => state.auth && state.auth.user && state.auth.user.permissions ? state.auth.user.permissions.indexOf(permission) >= 0 : false
}

const getters = {
  isAuthenticated: state => !!state.user.username,
  user: state => state.user,
  userInfo: state => () => state.user,
  isAdmin: state => state.user ? state.user.roles.Admin : false,
  hasRoles: state => role => state.user && state.user.roles ? !!state.user.roles[role] : false,
  hasPermission: state => permission => {
    if (Array.isArray(permission)) {
      for (const p of permission) {
        if (state.user.permissions.indexOf(p) >= 0) {
          return true
        }
        return false
      }
    } else {
      return state.user && state.user.permissions ? state.user.permissions.indexOf(permission) >= 0 : false
    }
  }
}

const actions = {
  async LogIn ({ commit }, user) {
    const auth = {
      accessId: user.accessId,
      accessToken: user.token,
      username: user.identification,
      permissions: user.permissions,
      roles: user.roles ? user.roles : []
    }

    axiosSetUserAuth(auth)
    await commit('SET_AUTH', auth)
    localStorage.setItem(USER_AUTH_KEY, JSON.stringify(auth))
  },
  async Logout ({ commit }) {
    // remove default header. Use delete. In other case if they are null or undefined will be send with those values
    delete axios.defaults.headers.common.Authorization
    delete axios.defaults.headers.common['X-AccessId']
    delete axios.defaults.headers.common['X-Username']
    localStorage.removeItem(USER_AUTH_KEY)
    commit('REMOVE_AUTH')
    commit('CLEAR_APP')
  }
}

const mutations = {
  SET_AUTH (state, { username, accessId, accessToken, permissions }) {
    state.user = { username, accessId, accessToken, permissions }
  },
  REMOVE_AUTH (state) {
    state.user = { username: null, accessToken: null, accessId: null, permissions: [], roles: [] }
  },
  CLEAR_APP (state) {
    state.app = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
