/*
 * This is state machine for manage the users
 */
const state = {}
const actions = {}
const mutations = {}
const getters = {}

export default {
  state,
  getters,
  actions,
  mutations
}
