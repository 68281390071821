import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import users from '@/store/modules/users'

// Load Vuex
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState],
  modules: {
    auth,
    users
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
    settings: {},
    app: {}
  },
  getters: {
    // isAdmin: state => {},
    // isManager
    // isRegular user
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    }
  },
  actions: {
  }
})
