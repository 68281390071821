import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/Login'),
    meta: { guest: true }
  },
  {
    path: '/',
    component: () => import('@/views/layout/Index'),
    meta: { requireAuth: true },
    children: [
      // Dashboard
      {
        name: 'Dashboard BETA',
        path: '',
        component: () => import('@/views/pages/dashboard/Dashboard'),
        meta: { requireAuth: true, permission: ['DASHBOARD', 'DASHBOARD_ADMIN'] }
      },
      // Page Reports
      {
        name: 'Reports',
        path: 'reports',
        component: () => import('@/views/pages/reports/Reports'),
        meta: { requireAuth: true, permission: 'REPORTS_PREVIEW' }
      },
      // Page Blotters
      {
        name: 'Blotter List',
        path: 'blotters',
        component: () => import('@/views/pages/blotters/BlotterList'),
        meta: { requireAuth: true }
      },
      {
        name: 'Blotter Details',
        path: 'blotters/:id',
        component: () => import('@/views/pages/blotters/BlotterDetails'),
        meta: { requireAuth: true }
      },
      //ManualPositions
      {
        name: 'Manual Positions',
        path: 'manual-positions',
        component: () => import('@/views/pages/manual-positions/ManualPositions'),
        meta: { requireAuth: true, permission: ['MANUAL_POSITION'] }
      },
      // Notifications
      {
        name: 'Notifications',
        path: 'notifications',
        component: () => import('@/views/pages/notifications/NotificationList'),
        meta: { requireAuth: true }
      },
      // Page Pdf Report
      {
        name: 'Pdf Report',
        path: 'pdf/reports',
        component: () => import('@/views/pages/reports/pdf/Reports'),
        meta: { requireAuth: true, permission: 'PDF_REPORTS' }
      },
      // Page Support
      {
        name: 'Support BETA',
        path: 'support',
        component: () => import('@/views/pages/support/Support'),
        meta: { requireAuth: true }
      },
      {
        name: 'Issues List BETA',
        path: 'support/:category',
        component: () => import('@/views/pages/support/IssuesList'),
        meta: { requireAuth: true }
      },
      {
        name: 'Issue Details BETA',
        path: 'support/:category/issue/:id',
        component: () => import('@/views/pages/support/IssuesDetails'),
        meta: { requireAuth: true }
      },
      {
        name: 'Library',
        path: 'library',
        component: () => import('@/views/pages/library/library.vue'),
        meta: { requireAuth: true, permission: ['LIBRARY_PREVIEW', 'LIBRARY_ADMIN'] }
      },
      {
        name: 'Library Preview',
        path: 'library/preview/:resourceId',
        component: () => import('@/views/pages/library/preview.vue'),
        meta: { requireAuth: true, permission: ['LIBRARY_PREVIEW', 'LIBRARY_ADMIN'] }
      },
      {
        name: 'MNDB Tool BETA',
        path: 'mndb-tool',
        component: () => import('@/views/pages/terminal/MndbToolTerminal'),
        meta: { requireAuth: true, permission: 'MNDB_TOOL_TERMINAL' }
      },
      {
        name: 'Disabled Symbols',
        path: 'disabled-symbols',
        component: () => import('@/views/pages/disabled-symbol/DisabledSymbols'),
        meta: { requireAuth: true, permission: 'MANAGE_SYSTEM_SETTINGS' }
      },
      {
        name: 'Sterling Reports',
        path: 'sterling-reports',
        component: () => import('@/views/pages/reports/sterling/SterlingReports'),
        meta: { requireAuth: true, permission: 'STERLING_REPORTS' }
      },
      {
        name: 'Eze Reports',
        path: 'eze-reports',
        component: () => import('@/views/pages/reports/eze/EzeReports'),
        meta: { requireAuth: true, permission: 'EZE_REPORTS' }
      },
      // Page Users
      {
        name: 'User List',
        path: 'users',
        component: () => import('@/views/pages/users/UserList'),
        meta: { requireAuth: true, permission: 'LIB_ACCESS_MODIFY' }
      },
      {
        name: 'User Management',
        path: 'users/:id',
        component: () => import('@/views/pages/users/UserManagement'),
        meta: { requireAuth: true, permission: 'LIB_ACCESS_MODIFY' }
      },
      // Monthly Statement
      {
        name: 'Bonus Statement',
        path: 'bonus-statement',
        component: () => import('@/views/pages/monthly-statement/MonthlyStatement'),
        meta: { requireAuth: true, permission: 'MONTHLY_STATEMENT' }
      },
      {
        name: 'Bonus Statement Admin',
        path: 'bonus-statement-admin',
        component: () => import('@/views/pages/monthly-statement/MonthlyStatementAdmin'),
        meta: { requireAuth: true, permission: 'MANAGE_MONTHLY_STATEMENT' }
      },
      // Page Profile
      {
        name: 'User Profile',
        path: 'profile',
        component: () => import('@/views/pages/users/UserProfile'),
        meta: { requireAuth: true }
      },
      // Page Settings
      {
        name: 'System Settings',
        path: 'system-settings',
        component: () => import('@/views/pages/system-settings/SystemSettings'),
        meta: { requireAuth: true }
      },
      {
        name: 'Settings',
        path: 'settings',
        component: () => import('@/views/pages/settings/Settings'),
        meta: { requireAuth: true }
      },
      {
        name: 'Versions',
        path: 'system-versions',
        component: () => import('@/views/pages/versions/Versions'),
        meta: { requireAuth: true, permission: 'SYSTEM_VERSIONS' }
      },
      // Page Timeline
      {
        name: 'Timeline',
        path: 'timeline',
        component: () => import('@/views/pages/timeline/Timeline'),
        meta: { requireAuth: true }
      }
    ]
  }
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// TODO check for specific roles
router.beforeEach((to, from, next) => {
  next()
})

export default router
